html {
  box-sizing: border-box;
}

html[data-agent*="Trident"] {
  .description__img::after {
    display: none !important;
  }

  .page-intro__card-img::after {
    display: none !important;
  }

  .feature__list::after {
    display: none !important;
  }

  .service-fee::after {
    display: none !important;
  }

  .page-intro__preview-app::before {
    display: none !important;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.page {
  min-width: 320px;
  min-height: 100%;

  &__wrapper {
    position: relative;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  &__content {
    flex-grow: 1;
  }

  &__footer {
    flex-shrink: 0;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  font-family: $font-family-main;
  font-size: $font-size-main;
  font-weight: $font-weight-main;
  line-height: $line-height-main;
  color: $color-main;

  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: #ebeef0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: #fff;
  outline: none;
  text-decoration: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: .5rem;
}

ul, ol {
  padding: 0;
  margin: 0 0 .5rem;
  list-style-type: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5px;

  @include phone() {
    max-width: 682px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include laptop() {
    max-width: 1200px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-title {
  margin-bottom: 15px;

  font-weight: 600;
  font-size: 28px;
  line-height: 1.2;
  text-align: center;
  color: $color-main;

  &__number {
    display: block;
    font-size: 48px;
    line-height: 1;
  }
  
  @include phone() {
    line-height: 1.28;
    text-align: left;

    margin-bottom: 12px;
  }

  @include laptop() {
    font-size: 40px;
    line-height: 1.2;
  }
}

.section-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.55;

  text-align: center;
  color: $color-main;

  &--small {
    font-size: 16px;
    line-height: 1.75;
  }
  
  @include phone() {
    font-size: 20px;
    line-height: 1.8;
    text-align: left;
  }

  @include laptop() {
    font-size: 24px;
    line-height: 1.67;
    p {
      margin-bottom: 10px;
    }    
  }
}

.row-reverse-tablet {
  flex-direction: row-reverse;
}

.scroll-top-btn {
  display: block;

  position: absolute;
  left: 20px;
  bottom: 304px;
  z-index: 1000;

  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  outline: none;

  border-radius: 50%;
  background-color: #3C3C3C;
  box-shadow: 0 4px 20px rgba(134, 134, 134, 0.15);

  &__icon {
    fill: #EAEAEA;
    width: 12px;
    height: 17px;
  }
  
  @media screen and (min-width: 460px) {
    bottom: 265px;
  }
  
  @include phone() {
    left: auto;
    right: 23px;
    bottom: 17px;
  }

  @include laptop() {
    right: 29px;
    bottom: 255px;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
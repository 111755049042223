.bcc-worldwide {
  margin-top: 65px;

  @include tablet() {
    margin-top: 0;
  }

  &__wrapper {

    @include phone() {
      margin-bottom: 100px;
    }

    @include laptop() {
      margin-bottom: 180px;
    }

    @include desktop() {
      margin-bottom: 100px;
    }
  }

  .description__wrapper {
    position: relative;
  }

  &__title {

  }

  &__text {
    max-width: 290px;
    width: 100%;
    margin: 0 auto 36px;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    @include phone() {
      max-width: 337px;
      margin: 0 0 36px;
    }

    @include laptop() {
      max-width: 500px;
      margin-right: 71px;
    }
  }

  &__animation {
    overflow: hidden;
    width: 270%;
    margin-top: -43vw;
    margin-bottom: -44vw;
    margin-left: -136vw;
    z-index: -1;

    @include phone() {
      position: absolute;
      left: 0;
      bottom: -130px;

      width: 100%;
      margin-left: 0;
      margin-top: -50px;
      margin-bottom: -50px;
    }

    @include tablet() {
      bottom: -180px;
    }

    @include laptop() {
      position: absolute;
      left: 0;
      bottom: -330px;
    }

    @include desktop() {
      bottom: -290px;
    }

    svg, img {
      height: 100%;
      width: 100%;
      max-width: initial;

      @include tablet() {
        width: 140%;
        margin-left: -165px;
      }

      @include laptop() {
        margin-left: -240px;
      }

      @include desktop() {
        margin-left: -120px;
        width: 119%;
      }
    }
  }
}

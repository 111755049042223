.description {
  margin-bottom: 35px;

  &--phone-pin {
    margin-bottom: 34px;
    margin-top: 10px;

    @include tablet() {
      margin-top: 0;
    }

    @include phone() {
      margin-bottom: 69px;

      .description__wrapper {
        max-width: 724px;
      }
    }
    
    @include laptop() {
      margin-bottom: 111px;

      .description__text {
        max-width: 470px;
        margin-right: 100px;
      }
    }

  }

  &--pocket {
    margin-bottom: 61px;
    img {
      width: 100%;
      height: 100%;
    }
    .description__text {
      margin-bottom: 27px;
    }

    @include phone() {
      margin-bottom: 68px;
      img {
        width: 100%;
      }

      .description__text {
        max-width: 286px;
        margin-bottom: 33px;
      }

      .description__img {
        margin-right: 25px;

      }
    }

    @include laptop() {
      margin-bottom: 50px;

      .description__text {
        max-width: 630px;
        margin-bottom: 48px;
        margin-left: 70px;
      }

      .description__img {
        position: relative;
        margin-right: 99px;
        img {
          height: 600px;
        }
      }
    }
  }

  &--backup {
    img {
      width: 100%;
      height: 100%;
    }    
    @include phone() {
      margin-bottom: 65px;
      img {
        width: 100%;
      }

      .description__img {
        margin-left: 33px;
      }
    }
    
    @include laptop() {
      margin-bottom: 50px;

      .description__img {
        position: relative;
        margin-left: 101px;
        img {
          height: 600px;
        }
      }

      .description__text {
        margin-right: 71px;
        margin-bottom: 17px;
      }
    }
  }

  &--transfer {
    margin-bottom: 60px;

    .description__text {
      margin-bottom: 31px;
    }

    @include phone() {
      margin-bottom: 67px;

      .description__img {
        img {
          width: 300px;
        }
      }

      .description__text {
        max-width: 306px;
        margin-bottom: 17px;
      }
    }

    @include laptop() {
      margin-bottom: 50px;

      .description__img {
        position: relative;


        img {
          width: auto;
          height: 600px;
        }
      }

      .description__text {
        max-width: 500px;
        margin-bottom: 31px;
        margin-left: 70px;
      }
    }
  }

  &--limit {
    margin-bottom: 57px;
    img {
      width: 100%;
      height: 100%;
    }

    .description__text {
      margin-bottom: 30px;
    }

    @include phone() {
      margin-bottom: 66px;
      img {
        width: 100%;
      }

      .description__img {
        margin-left: 33px;
      }

      .description__text {
        margin-bottom: 0;
      }
    }

    @include laptop() {
      margin-bottom: 50px;

      .description__img {
        position: relative;
        margin-left: 101px;
        img {
          height: 600px;
        }
      }

      .description__text {
        margin-right: 70px;
        margin-bottom: 15px;
      }
    }
  }

  &--support {
    img {
      width: 100%;
      height: 100%;
    }    
    .section-title {
      margin-bottom: 8px;
    }

    .description__text {
      margin-bottom: 32px;
    }

    .description__img {
      position: relative;
    }

    @include phone() {
      margin-bottom: 74px;
      img {
        width: 100%;
      }

      .description__img {
        margin-right: 26px;

        &::after {
          width: 321px;
          height: 341px;
          background: rgba(125, 125, 125, 0.8);
        }
      }

      .description__text {
        margin-bottom: 24px;
        padding-right: 23px;
      }

      .section-title {
        text-align: center;
      }

      .section-text {
        text-align: center;
      }
    }

    @include laptop() {
      margin-bottom: 95px;

      .description__img {
        margin-right: 147px;
        img {
          height: 600px;
        }

      }

      .description__text {
        margin-bottom: 24px;
        padding-right: 0;
        margin-left: 71px;
      }

      .section-title {
        text-align: left;
      }

      .section-text {
        text-align: left;
      }
    }
  }

  @include phone() {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    max-width: 280px;
    width: 100%;
    margin: 0 auto 36px;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    @include phone() {
      max-width: 337px;
      margin: 0 0 36px;
    }

    @include laptop() {
      max-width: 500px;
    }
  }

  &__img--center {
    display: flex;
    justify-content: center;
    padding-left: 25px;
    @include laptop() {
      margin-left: 0px;
    }
  }

  &__img {

    @include laptop() {
      width: 100%;
    }

    &_left {

      @include phone() {
        margin: 0 !important;
        padding: 0 25px 0 30px !important;
      }
    }

    &_right {

      @include phone() {
        margin: 0 !important;
        padding: 0 30px 0 25px !important;
      }
    }
  }

  &__text {

    &_left {

      @include laptop() {
        margin: 0 0 17px 71px !important;
      }
    }

    &_right {

      @include laptop() {
        /*margin-right: 71px;
        margin-bottom: 17px;*/
        margin: 0 71px 17px 0 !important;
      }
    }
  }

}

.phone-pin {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-left: 25px;
  img {
    width: 100%;
    height: 100%;
  } 
  @include phone() {
    max-width: 307px;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      margin-left: 25px;
    } 
  }

  @include laptop() {
    width: auto;
    height: 600px;
    max-width: none;
    img {
      width: auto;
      height: 600px;
    } 
  }


  &__phone {
    position: relative;
    z-index: 2;

    @include phone() {
      img {
        width: 100%;
        height: 100%;
      }      

    }

    @include laptop() {
      width: auto;
      height: 600px;
      max-width: none;
      img {
        width: 100%;
        height: 100%;
      } 
    }
  }
}

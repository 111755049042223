.service-fee {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 49px;

  @include phone() {
    margin-bottom: 62px;
  }

  @include laptop() {
    margin-bottom: 128px;
  }

  &__wrapper {
    @include phone() {
      max-width: 646px;
    }

    @include laptop() {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__card {
    display: none;

    @include laptop() {
      position: relative;
      display: block;
      max-width: 472px;
      width: 100%;
      flex: 1 1 auto;
    }
  }

  &__main {
    padding: 20px 18px 29px;
    border-radius: 18px;
    background-color: #f7f8f8;
    color: #000;

    @include phone() {
      display: flex;
      justify-content: space-between;
      align-items: center;

      min-height: 204px;
      padding-left: 37px;
      padding-right: 31px;
    }
    
    @include laptop() {
      flex: 1 1 auto;
      min-height: 230px;
      padding-right: 73px;
      padding-left: 59px;
      padding-top: 16px;
      border-radius: 0 18px 18px 0;
      margin-bottom: 45px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.28;
    text-align: center;
    color: #000;
    margin-bottom: 12px;

    sup {
      font-size: 24px;
      top: -3px;
      margin-left: 2px;
      color: #8DC63F;
    }

    @include phone() {
      font-size: 28px;
      margin-bottom: 0;
      align-self: start;
      padding-top: 20px;  
      text-align: left;
    }
  }

  &__per-month {
    margin-bottom: 20px;

    @include phone() {
      margin-bottom: 0;
      padding-top: 22px;
    }

    @include laptop() {
      padding: 0 15px;
    }
  }

  &__per-month-number {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    min-height: 87px;
  }

  &__per-month-number span {
    font-family: "Exo 2", sans-serif;
    display: block;
    font-weight: 600;
    font-size: 120px;
    line-height: .6;
  }

  &__per-month-icon {
    display: block;
    width: 34px;
    height: 37px;
    fill: #8DC63F;
    transform: translate(6px, 4px);
  }

  &__per-month-text {
    font-weight: 600;
    font-size: 23px;
    line-height: 1.21;
    text-align: center;

    @include phone() {
      font-family: "Exo 2", sans-serif;
      text-align: left;
    }
  }
  

}